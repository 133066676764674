import React from "react"
import { DefaultHeader } from "components/headers/default-header"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
// import { StaticImage } from "gatsby-plugin-image"
import { config } from "@fortawesome/fontawesome-svg-core"
import { CvBtn } from "components/case_study/cv-btn"
import { StarRates } from "components/case_study/star-rates"
import { FlowNumber } from "components/case_study/flow-number"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faArrowDown,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
config.autoAddCss = false

// キャンペーン概要
const features = [
  { color: "bg-case_blue", title: "拡散度", content: "とても高い" },
  {
    color: "bg-case_orange",
    title: "抽選方法",
    content: "即時抽選（Wチャンスのみ後日抽選）",
  },
  { color: "bg-accent", title: "結果確認方法", content: "DM" },
]

// こんな方におすすめ文言
const recommends = [
  { title: "初めてキャンペーンを開催する" },
  { title: "キャンペーン開催の予算が少ない" },
  { title: "フォロワーの増加を第一目標にしたい" },
  { title: "ギフトコードの送付で、プレゼント送付の手間を省きたい" },
  { title: "自社のクーポンコードを配布し、販促に繋げたい" },
  { title: "通常のインスタントウィンより、さらにツイートを拡散させたい" },
]

const InstantWinFollowRetweetDoubleChance = ({ location }) => {
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="Twitterインスタントウィン事例・リツイートでギフト券を自動送信"
          pagepath={location.pathname}
          pagedesc="キャンペーン主催のTwitterアカウントをフォロー、指定投稿をリツイートで、当選者に即時でギフト券をDM送信することができるキャンペーンです。条件を満たした参加者にはWチャンスで景品が当たるチャンスもあり、ワクワクが止まりません。Twitterの認知度向上に最適なキャンペーンです。"
        />
        <main className="case_study main">
          {/* メインビジュアル */}
          <section className="hero h-auto">
            <div className="inner relative py-5 md:py-8 px-4">
              <h1 className="text-white font-bold md:mb-4 mb-3">
                フォロー＆リツイート・ギフト券送付・
                <br className="block" />
                Wチャンスあり（インスタントウィン）
              </h1>
              <p className="text-white md:text-xl text-lg font-bold leading-relaxed">
                フォロー＆リツイートで当選者に即時でギフト券をDM送信することができます。
                <br className="md:block" />
                さらに、条件を満たした参加者にはWチャンスで景品が当たるチャンスも！
                <br className="md:block hidden" />
                ワクワクが止まらない、認知度UPには最適なキャンペーンです。
              </p>
              <div className="fv_img absolute -right-8 top-32">
                <img
                  layout="fullWidth"
                  alt="フォロー＆リツイート・ギフト券送付・Wチャンスあり（インスタントウィン）"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/mv_instant_win_follow_retweet_double_chance.png"
                />
              </div>
            </div>
          </section>
          {/* こんな方におすすめです */}
          <section className="recommend bg-secondary">
            <div className="inner py-6 md:pt-10 md:pb-28 px-4">
              <ul className="flex md:mb-8 mb-6 gap-2">
                {features.map((feature, index) => {
                  return (
                    <li key={index} className="text-center font-bold">
                      <p
                        className={`text-white ${feature.color} py-1 md:px-6 px-1 text-sm md:text-xl`}
                      >
                        {feature.title}
                      </p>
                      <p className="text-sm md:text-xl bg-white py-1 md:px-6 px-3">
                        {feature.content}
                      </p>
                    </li>
                  )
                })}
              </ul>
              <h2 className="text-2xl md:text-3xl text-primary font-bold pb-2 border-b-2 border-primary md:w-3/4 mb-3">
                こんな方におすすめです
              </h2>
              <div className="">
                {recommends.map((recommend, index) => {
                  return (
                    <div key={index} className="flex items-center mb-1">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-primary mr-3"
                      />
                      <p className="font-bold text-text md:text-xl">
                        {recommend.title}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          {/* キャンペーンの特徴 */}
          <section className="campaign-feat bg-white">
            <div className="inner md:py-20 px-4 pt-8 pb-10">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                キャンペーンの特徴
              </h2>
              <div className="flex md:flex-row flex-col md:gap-10 gap-4">
                <div className="flex gap-4 flex-1">
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-case_blue text-white text-center text-sm md:text-base">
                      参加者
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">手軽さ</p>
                      <StarRates rate={5} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">
                        モチベーション
                      </p>
                      <StarRates rate={5} />
                    </div>
                  </div>
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-primary text-white text-center text-sm md:text-base">
                      ご担当者様
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">手軽さ</p>
                      <p className="font-bold text-xs text-text mb-1">
                        （ツールを使用しない場合）
                      </p>
                      <StarRates rate={2} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">手軽さ</p>
                      <p className="font-bold text-xs text-text mb-1">
                        （ツールを使用した場合）
                      </p>
                      <StarRates rate={4} />
                    </div>
                  </div>
                </div>
                <p className="flex-1 text-text">
                  非常にシンプルで、フォロワーの増加に効果的なキャンペーンです。
                  <br />
                  <br />
                  フォローとリツイートの2クリックで参加できるため、ユーザーの参加のハードルは低く、
                  拡散性も高いです。
                  <br />
                  <br />
                  Amazonギフト券、QuoカードPayなど他社サービスコードの送付はもちろんのこと、自社のクーポンコードを送って販促に繋げられます。
                  <br />
                  <br />
                  すぐに当選者にコードがDM送信されるため、ユーザーの参加モチベーションも高いです。
                  <br />
                  <br />
                  インスタントウィンのプレゼントより、Wチャンスキャンペーンのプレゼントを豪華に設定することで、さらにツイートを拡散できます。
                  <br />
                  <br />
                  フォロー&amp;リツイートされるとすぐに当落連絡を参加者に送る必要があるため、キャンペーンツールの導入が必須になります。
                </p>
              </div>
            </div>
          </section>
          {/* 弊社でのキャンペーン実績 */}
          {/* <section className="campaign_results">
            <h2 className="title font-semibold text-center">弊社でのキャンペーン実績</h2>
            <div className="inner">
              <div className="left bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
              <div className="right bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
            </div>
          </section> */}
          {/* PARKLoTのキャンペーンツールを導入するとどう変わる？ */}
          <section className="before_after bg-secondary">
            <div className="inner pt-8 pb-10 md:py-20 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                通常のフォロー&amp;リツイート
                <br className="block" />
                キャンペーンとどう違うの？
              </h2>
              <p className="mb-6 text-text">
                インスタントウィンは、キャンペーンツールの利用が必須になりますが、実はキャンペーンツールを導入することで、手動でのフォロー&amp;リツイートのキャンペーンと比較して大幅に運用時間を短縮することができます！
                <br />
                <br />
                また、当選者のみですが、DMで自社のクーポンコードの送信ができますので、自社商品の販促にも繋げられます。
              </p>
              <div className="flex flex-col md:flex-row md:gap-10 gap-4">
                <div className="flex-1 border border-primary rounded bg-white">
                  <h3 className="bg-primary font-bold text-xl leading-normal text-center text-white py-3">
                    手動でキャンペーンを行なった場合
                  </h3>
                  <div className="rounded-b p-4">
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      Twitterから該当キャンペーンページを開く
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      参加者一覧表示
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue">
                      <div className="flex justify-center items-center mb-2">
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          ユーザーの詳細画面確認
                        </div>
                        <div className="mx-2">
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="text-lightgrey text-base"
                          />
                        </div>
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          当選者決定
                        </div>
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        当選者の数だけ繰り返す
                      </p>
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue mb-4 text-center">
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3">
                        当選者アカウントを検索する
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3 mb-2">
                        DMの作成・確認・送信
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        当選者の数だけ繰り返す
                      </p>
                    </div>
                    <h3 className="text-center font-bold text-text mb-3">
                      キャンペーン終了後の作業だけで
                      <br className="block md:hidden" />
                      <span className="font-bold text-2xl text-accent">
                        3〜5時間必要
                      </span>
                    </h3>
                    <ul className="text-xs list-disc leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                      <li>
                        Twitterにログインし、
                        <span className="font-bold">
                          参加者のプロフィールを1件1件確認する
                        </span>
                        必要がある
                      </li>
                      <li>
                        当選者に
                        <span className="font-bold">1件1件DMを送る</span>
                        必要がある
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="border border-accent rounded md:mb-10 mb-4">
                    <h3 className="font-bold text-xl leading-normal bg-accent text-center text-white py-3">
                      PARKLoTを利用した場合
                    </h3>
                    <div className="bg-white rounded-b p-4">
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        PARKLoTで作成したCSVから当選者を決定
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        当選者一覧のCSVを作成
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center text-sm font-bold text-accent bg-accent_pale rounded leading-normal py-4 mb-4">
                        PARKLoTが当選者にDM一括送信
                      </div>
                      <h3 className="text-center text-text font-bold mb-3">
                        キャンペーン終了後の作業が
                        <br className="md:hidden block" />
                        <span className="text-2xl text-accent leading-normal">
                          20〜30分で完了！
                        </span>
                      </h3>
                      <ul className="text-xs list-disc text-text leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                        <li>
                          参加者一覧を、
                          <span className="font-bold">
                            ソート、フィルタリングの上CSVで確認
                          </span>
                          できる
                        </li>
                        <li>
                          当選・落選
                          <span className="font-bold">
                            DMの一括送信をPARKLoTに依頼
                          </span>
                          できる
                        </li>
                        <li>
                          （二次効果として）
                          <span className="font-bold">
                            応募者の傾向を分析、今後のキャンペーンに活かせる
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bottom border border-lightgrey rounded">
                    <h3 className="text-xl font-bold bg-lightgrey leading-normal text-center text-white py-3">
                      以下の場合ツールの導入を
                      <br className="block md:hidden" />
                      おすすめしません
                    </h3>
                    <div className="bg-white rounded-b py-4 pr-4 pl-8">
                      <ul className="font-bold text-text list-disc leading-normal md:p-4 p-1">
                        <li>応募者の情報が不要</li>
                        <li>当選者数が少ない</li>
                        <li>ツール導入の費用がない</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
          {/* PARKLoTを使用した際の具体的なキャンペーン手順について */}
          <section className="usage_flow bg-white">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                PARKLoTを使用した際の
                <br />
                具体的なキャンペーン手順
                <br className="block md:hidden" />
                について
              </h2>

              <FlowNumber
                num="01"
                parklot={false}
                title="キャンペーン情報を管理画面からご登録ください"
              >
                <p className="text-text">
                  キャンペーン開始の3営業日前までに、管理画面からキャンペーン情報をご登録ください。
                  <br />
                  必要なデータは、
                  <a
                    href="https://xhackttl.notion.site/e0333dd6136c4ffa9462bf71d14ac74f"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-accent underline"
                  >
                    【フォロー＆リツイートキャンペーン・ギフト券DM配布】スケジュールと必要なデータ
                  </a>
                  からご確認いただけます。
                </p>
              </FlowNumber>

              <FlowNumber
                num="02"
                parklot={false}
                title="ギフトコード一覧をCSVでご提供下さい"
              >
                <p className="text-text">
                  キャンペーン開始３営業日前までに、ギフトコードの一覧をCSVでご提供ください。
                  <br />
                  弊社にて、キャンペーンデータとの紐付けを行います。
                </p>
              </FlowNumber>

              <FlowNumber
                num="03"
                parklot={true}
                title="キャンペーンツイートが自動投稿され、キャンペーンが開始します"
              >
                <p className="text-text">
                  開始時間になると、ツイートが自動投稿され、キャンペーンが開始します。
                </p>
              </FlowNumber>

              <FlowNumber
                num="04"
                parklot={true}
                title="当選者が出ると、自動でDMを送信してデジタルコードをお知らせします"
              >
                <p className="text-text">
                  リツイートユーザから、当選確率を計算して自動で当選者を選出します。
                  <br />
                  当選者が出ると、自動でDMを送信し、ギフトコードをお知らせします。
                  <br />
                  <br />
                  キャンペーン参加者の一覧は、管理画面から確認することができます。
                </p>
              </FlowNumber>

              <FlowNumber
                num="05"
                parklot={false}
                title="キャンペーン終了後、Wチャンスの当選者を選出・お知らせください"
              >
                <p className="text-text">
                  キャンペーン終了後に、Wチャンスの当選者を選出してください。
                  <br />
                  参加者の一覧は、管理画面からCSVでダウンロードすることができます。
                </p>
              </FlowNumber>

              <FlowNumber
                num="06"
                parklot={true}
                diagram={true}
                last={true}
                title="Wチャンスの当選者にDMを送信します"
              >
                <p className="text-text">
                  ご担当者様からお送り頂いた当選文とCSVを元に、Wチャンスの当選者にDMを一括送信致します。
                </p>
                <div>
                  <img
                    layout="constrained"
                    alt="当選者へのDM"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/usage_flow05.jpg"
                  />
                </div>
              </FlowNumber>
            </div>
          </section>
          {/* PARKLoTの月額料金に含まれる機能は？ */}
          <section className="parklot_function bg-secondary">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                PARKLoTの月額料金に
                <br className="block md:hidden" />
                含まれる機能は？
              </h2>
              <p className="text-text md:mb-10 mb-5">
                一般的なインスタントウィン、ギフトコード送付キャンペーンは、月額料金内でご利用頂けます。
              </p>
              <h3 className="text-lg text-text font-bold text-center md:mb-6 mb-4">
                月額料金内に含まれる機能
              </h3>
              <div className="flex md:flex-row flex-col gap-4 md:gap-10 md:justify-around">
                <div className="p-4 md:p-6 text-center bg-white">
                  <img
                    layout="constrained"
                    alt="リツイートユーザの取得"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/parklot_function01.png"
                    objectfit="contain"
                    className="mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">
                    リツイートユーザの取得
                  </h4>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <img
                    layout="constrained"
                    alt=" キャンペーン参加者一覧CSVのご提供"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/parklot_function02.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text mb-1">
                    キャンペーン参加者一覧CSVのご提供
                  </h4>
                  <p className="text-text text-xs">
                    *複雑なカスタマイズが必要な場合、
                    <br />
                    別途料金を頂く可能性がございます
                  </p>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <img
                    layout="constrained"
                    alt="当選者、落選者へのDM送信"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/parklot_function03.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">
                    当選者、落選者へのDM送信
                  </h4>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
        </main>
      </Layout>
    </>
  )
}

export default InstantWinFollowRetweetDoubleChance
